const ApiUrl = process.env.NEXT_PUBLIC_API_URL;

const UrlParamsReplace = (url, params = {}) => {
    let urlWithPrefix = `${ApiUrl}${url}`;
    if (params) {
        Object.keys(params).forEach((key) => (urlWithPrefix = urlWithPrefix.replace(`:${key}`, params[key])));
    }
    return urlWithPrefix;
};

export const GET_CATALOG_URL = (offset, limit, bodyType, search) =>
    UrlParamsReplace("/base-product-list?offset=:offset&limit=:limit&search=:search&bodyType=:bodyType", { offset, limit, bodyType, search });

export const GET_CATALOG_DETAILS_URL = (id, country, languageId) => UrlParamsReplace("/base-product/:id/:country?languageId=:languageId", { id, country, languageId });
export const GET_CATALOG_DETAILS_BY_SLUG_URL = (slug, country, languageId) => UrlParamsReplace("/base-product?slug=:slug&country=:country&languageId=:languageId", { slug, country, languageId });

export const CREATE_CUSTOMER_URL = UrlParamsReplace("/customers");
export const UPDATE_CUSTOMER_URL = ({ customerId }) => UrlParamsReplace("/customers/:customerId", { customerId });
export const GET_CUSTOMER_BY_ID_URL = ({ customerId }) => UrlParamsReplace("/customers/:customerId", { customerId });
export const GET_CUSTOMER_CONTRACT_CONTACT_URL = ({ contractId, customerId }) => UrlParamsReplace("/customer-contract-contact?contractId=:contractId&customerId=:customerId", { contractId, customerId });

export const CREATE_CUSTOMER_SESSION_URL = UrlParamsReplace("/customer-session");
export const GET_CUSTOMER_SESSION_BY_ID_URL = ({ sessionId }) => UrlParamsReplace("/customer-sessions/:sessionId", { sessionId });
export const UPDATE_SESSION_WITH_LOGGED_IN_USER_URL = UrlParamsReplace("/session-with-logged-in-user");
export const GET_CUSTOMER_SESSION_DETAILS_URL = ({ customerId }) => UrlParamsReplace("/customer-sessions?customerId=:customerId", { customerId });
export const DELETE_CUSTOMER_SESSION_URL = ({ sessionId }) => UrlParamsReplace("/customer-sessions/:sessionId", { sessionId });
export const UPDATE_CUSTOMER_SESSION_URL = ({ email }) => UrlParamsReplace("/customer-sessions/:email", { email });
export const UPDATE_CUSTOMER_SESSION_BY_ID_URL = ({ sessionId }) => UrlParamsReplace("/customer-sessions/:sessionId", { sessionId });
export const SYNC_SESSION_WITH_CONTRACT_URL = ({ sessionId }) => UrlParamsReplace("/sync-customer-sessions/:sessionId", { sessionId });

export const GET_LATEST_CUSTOMER_ADDRESSE_MOBILE_URL = ({ customerId }) => UrlParamsReplace("/customer-latest-address-mobile?customerId=:customerId", { customerId });
export const GET_DEFAULT_CUSTOMER_ADDRESSES_URL = ({ customerId, type }) => UrlParamsReplace("/default-address?customerId=:customerId&type=:type", { customerId, type });
export const GET_HOME_BILLING_OR_DELIVERY_ADDRESSES_URL = ({ customerId, contractId }) => UrlParamsReplace("/home-billing-or-delivery-address?customerId=:customerId&contractId=:contractId", { customerId, contractId });

export const CREATE_CUSTOMER_ADDRESS_URL = UrlParamsReplace("/customers-address");
export const UPDATE_CUSTOMER_ADDRESSES = ({ customerId }) => UrlParamsReplace("/customers-address/?customerId=:customerId", { customerId });
export const UPDATE_CUSTOMER_DATE_AND_ADDRESSES = ({ customerId, contractId }) => UrlParamsReplace("/customers-date-and-address/?customerId=:customerId&contractId=:contractId", { customerId, contractId });
export const UPDATE_COLLECTION_ADDRESS_AND_DATE_URL = ({ contractId }) => UrlParamsReplace("/collection-address-and-date/:contractId", { contractId });

export const CONTRACT_STOP_REASONS_URL = ({ type }) => UrlParamsReplace("/contract-stop-reasons?type=:type", { type });
export const UNCANCEL_SUBSCRIPTION_URL = ({ contractId }) => UrlParamsReplace("/uncancel-subscription/:contractId", { contractId });
export const CANCEL_SUBSCRIPTION_URL = ({ contractId }) => UrlParamsReplace("/cancel-subscription/:contractId", { contractId });
export const IMMEDIATE_CANCEL_SUBSCRIPTION_URL = ({ contractId }) => UrlParamsReplace("/immediate-cancel-subscription/:contractId", { contractId });
export const CANCEL_PAUSE_SUBSCRIPTION_URL = ({ contractId }) => UrlParamsReplace("/cancel-pause-subscription/:contractId", { contractId });
export const PAUSE_SUBSCRIPTION_URL = ({ contractId }) => UrlParamsReplace("/pause-subscription/:contractId", { contractId });
export const MANAGE_SUBSCRIPTION_URL = ({ contractId }) => UrlParamsReplace("/manage-subscription/:contractId", { contractId });
export const RESUME_SUBSCRIPTION_URL = ({ contractId }) => UrlParamsReplace("/resume-subscription/:contractId", { contractId });
export const CANCEL_SUBSCRIPTION_WHEN_PAUSED_URL = ({ contractId }) => UrlParamsReplace("/cancel-subscription-when-paused/:contractId", { contractId });

export const CAR_RETURNED_EVENT_URL = UrlParamsReplace("/car-returned");

export const GET_STRIPE_SUBSCRIPTION_URL = (subscriptionId) => UrlParamsReplace("/stripe-subscription/:subscriptionId", { subscriptionId });
export const CREATE_STRIPE_SUBSCRIPTION_URL = UrlParamsReplace("/stripe-subscription");
export const GET_UPCOMING_INVOICE_DETAILS = UrlParamsReplace("/stripe-get-upcoming-invoice");
export const GET_PAYMENT_DETAIL_AND_CUSTOMER_PM = ({ paymentId }) => UrlParamsReplace("/payment-detail-and-customers-pm/:paymentId", { paymentId });
export const GET_FAILED_PAYMENTS_AND_CUSTOMERS_PM = ({ customerId, contractId }) => UrlParamsReplace("/failed-payments-and-customers-pm?customerId=:customerId&contractId=:contractId", { customerId, contractId });
export const GET_FAILED_PAYMENT_INTENT_URL = ({ customerId, contractId, paymentId }) => UrlParamsReplace("/failed-payment-intent?customerId=:customerId&contractId=:contractId&paymentId=:paymentId", { customerId, contractId, paymentId });
export const GET_ADVANCE_PAYMENT_AND_ASSOCIATED_P_METHODS = ({ customerId, contractId }) => UrlParamsReplace("/advance-payment-intent?customerId=:customerId&contractId=:contractId", { customerId, contractId });
export const CREATE_STRIPE_SETUP_INTENT_URL = UrlParamsReplace("/create-setup-intent");
export const GET_STRIPE_SETUP_INTENT_URL = ({ setupIntentId, customerId, contractId }) => UrlParamsReplace("/setup-intents/:setupIntentId?customerId=:customerId&contractId=:contractId", { setupIntentId, customerId, contractId });
export const CREATE_STRIPE_IDENTITY_VERIFICATION_SESSION_URL = UrlParamsReplace("/create-identity-verification-session");
export const DETACH_PAYMENT_METHOD_STRIPE_URL = UrlParamsReplace("/detach-payment-method");
export const MARK_AS_DEFAULT_PAYMENT_METHOD_STRIPE_URL = UrlParamsReplace("/mark-as-default-payment-method");

export const GET_CATALOG_GROUP_MASTER_URL = (country) => UrlParamsReplace("/bp-group-master/:country", { country });
export const GET_PRODUCT_PLANS = (productId, country) => UrlParamsReplace("/base-product-plan/:productId/:country", { productId, country });

export const LOGIN_WITH_GOOGLE_URL = UrlParamsReplace("/login-with-google");
export const LOGIN_WITH_EMAIL_URL = UrlParamsReplace("/login-with-email");
export const VERIFY_OTP_WITH_EMAIL_URL = UrlParamsReplace("/verify-otp-with-email");
export const LOGIN_WITH_APPLE_URL = UrlParamsReplace("/login-with-apple");

export const GET_SUBSCRIPTION_URL = (customerId) => UrlParamsReplace("/contracts-list?customerId=:customerId", { customerId });
export const GET_SUBSCRIPTION_BY_ID_URL = (contractId, access) => UrlParamsReplace("/contracts-details/:contractId?access=:access", { contractId, access });
export const GET_CONTRACT_FOR_MANAGE_SUBSCRIPTION_URL = (contractId) => UrlParamsReplace("/contract-manage-subscription/:contractId", { contractId });

export const GET_INVOICES_BY_CONTRACT_ID_URL = ({ contractId }) => UrlParamsReplace("/invoices?contractId=:contractId", { contractId });

export const SEND_OTP_WITH_MOBILE_URL = (customerId) => UrlParamsReplace("/customers/:customerId/send-otp", { customerId });
export const RESEND_OTP_WITH_MOBILE_URL = (customerId, otpId) => UrlParamsReplace("/customers/:customerId/send-otp/:otpId", { customerId, otpId });
export const VERIFY_OTP_WITH_MOBILE_URL = (customerId) => UrlParamsReplace("/customers/:customerId/verify-otp", { customerId });


export const GET_ADD_ON_LIST_URL = ({ contractId, country }) => UrlParamsReplace("/ep-group-line-item/:contractId?country=:country", { country, contractId });
export const UPDATE_ADD_ON_TO_SUBSCRIPTION_URL = (contractId) => UrlParamsReplace('/update-add-on/:contractId', { contractId });
export const GET_ADD_ON_DETAILS_URL = (country, name) => UrlParamsReplace('/experience-product/:name/:country', { name, country });




export const GET_DRIVING_LICENSE_DETAILS_URL = (customerId) => UrlParamsReplace('/driving-license-details/:customerId', { customerId });
export const UPDATE_DRIVING_LICENSE_DETAILS_URL = (id) => UrlParamsReplace('/driving-license-details/:id', { id });

export const DUMP_LOGS_TO_AWS_CLOUD_WATCH_URL = UrlParamsReplace("/dump-logs-aws");

export const GET_CHECK_LIST_URL = (contractId, customerId) => UrlParamsReplace("/contract-checklist/:contractId/:customerId", { contractId, customerId });

export const GET_ADDITIONAL_DRIVER_URL = (contractId) => UrlParamsReplace("/additional-drivers/:contractId", { contractId });
export const CREATE_ADDITIONAL_DRIVER_URL = (contractId) => UrlParamsReplace("/additional-drivers/:contractId", { contractId });
export const UPDATE_ADDITIONAL_DRIVER_URL = (contractId, customerId) => UrlParamsReplace("/additional-drivers/:contractId/customer/:customerId", { contractId, customerId });
export const RETRY_TO_ADD_ADDITIONAL_DRIVER_URL = (contractId, driverId, customerId) => UrlParamsReplace("/additional-drivers/:contractId/driver/:driverId?customerId=:customerId", { contractId, driverId, customerId });
export const GET_ADDITIONAL_DRIVER_DETAILS_URL = (contractId, customerId) => UrlParamsReplace("/additional-drivers/:contractId/customer/:customerId/details", { contractId, customerId });
export const UPDATE_ADDITIONAL_DRIVER_REQUEST_URL = (contractId, customerId) => UrlParamsReplace("/additional-drivers/:contractId/status-update/:customerId", { contractId, customerId });
export const DEACTIVATE_ADDITIONAL_DRIVER_REQUEST_URL = (contractId, customerId) => UrlParamsReplace("/additional-drivers/:contractId/deactivate/:customerId", { contractId, customerId });
export const REMOVE_ADDITIONAL_DRIVER_REQUEST_URL = (contractId, customerId) => UrlParamsReplace("/additional-drivers/:contractId/remove/:customerId", { contractId, customerId });
export const REACTIVATE_ADDITIONAL_DRIVER_REQUEST_URL = (contractId, customerId, country) => UrlParamsReplace("/additional-drivers/:contractId/reactivate/:customerId?country=:country", { contractId, customerId, country });


export const GET_COUPON_DETAIL = (couponId) => UrlParamsReplace("/coupons/:couponId", { couponId });
export const APPLY_COUPON_SUBSCRIPTION = UrlParamsReplace("/apply-coupons-subscription")
export const REMOVE_COUPON_SUBSCRIPTION = UrlParamsReplace("/remove-coupons-subscription")

export const GET_VEHICLE_DETAIL = (contractId) => UrlParamsReplace("/vehicles/:contractId", { contractId });

export const UPDATE_FUEL_LEVEL_URL = (contractId) => UrlParamsReplace("/contract/:contractId/fuel-level", { contractId });
export const UPDATE_VEHICLE_MILEAGE_FUEL_LEVEL_URL = (contractId) => UrlParamsReplace("/vehicle-mileage-fuel-level/:contractId", { contractId });
export const GET_BOOKING_DETAIL = (contractId) => UrlParamsReplace("/booking-details/:contractId", { contractId });

//lead details for different env
export const CREATE_LEAD_FOR_QA_URL = "https://zenova-qa.napses.in/api/lead-information"
export const CREATE_LEAD_FOR_UAT_URL = "https://zenova-staging.zenova.com/api/lead-information"
export const CREATE_LEAD_FOR_Dev_URL = "http://localhost:3000/lead-information"

export const CREATE_LEAD_DETAILS_URL = UrlParamsReplace("/lead-information")

