import axios from "axios";
import { getAuth } from "./identity.service";

export const postWithAuth = (url, entity, countryIsoCode) => {
    const auth = getAuth();
    return postAuthWithAccessToken(url, auth?.accessToken, entity, countryIsoCode)
};

export const putWithAuth = (url, entity) => {
    const auth = getAuth();
    return putAuthWithAccessToken(url, auth?.accessToken, entity)
};


export const getWithAuth = (url, countryIsoCode = 'DE') => {
    const auth = getAuth();
    return getAuthWithAccessToken(url, auth?.accessToken, countryIsoCode)
};

export const getWithOutAuth = (url, countryIsoCode = 'DE') => {
    return new Promise((resolve, reject) => {
        const headers = {
            "content-type": "application/json",
            'country-iso-code': countryIsoCode
        };
        axios
            .get(url, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve(response.data);
                }
            })
            .catch((ex) => {
                resolve({ status: false, message: ex?.message, errorMessage: ex?.response?.data?.message, statusCode: ex?.response?.status });
            });
    });
};
export const postWithOutAuthForTenant = (url, entity, tenantId, countryIsoCode = 'DE',) => {
    return new Promise((resolve, _reject) => {
        const headers = {
            "content-type": "application/json",
            'country-iso-code': countryIsoCode,
            'x-tenant-id': tenantId,
        };
        axios
            .post(url, entity, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve(response.data);
                }
            })
            .catch((ex) => {
                resolve({ status: false, message: ex.message, errorMessage: ex?.response?.data?.message, statusCode: ex?.response?.status });
            });
    });
}

export const postWithOutAuth = (url, entity, countryIsoCode = 'DE') =>
    new Promise((resolve, _reject) => {
        const headers = {
            "content-type": "application/json",
            'country-iso-code': countryIsoCode
        };
        axios
            .post(url, entity, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve(response.data);
                }
            })
            .catch((ex) => {
                resolve({ status: false, message: ex.message, errorMessage: ex?.response?.data?.message, statusCode: ex?.response?.status });
            });
    });

export const putWithOutAuth = (url, entity, countryIsoCode = 'DE') =>
    new Promise((resolve, _reject) => {
        const headers = {
            "content-type": "application/json",
            'country-iso-code': countryIsoCode
        };

        axios
            .put(url, entity, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve(response.data);
                }
            })
            .catch((ex) => {
                resolve({ status: false, message: ex.message, errorMessage: ex?.response?.data?.message, statusCode: ex?.response?.status });
            });
    });


export const getAuthWithAccessToken = (url, accessToken, countryIsoCode = 'DE') => {
    return new Promise((resolve, reject) => {
        const headers = {
            "content-type": "application/json",
            "x-access-token": accessToken,
            'country-iso-code': countryIsoCode
        };
        axios
            .get(url, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve(response.data);
                }
            })
            .catch((ex) => {
                if (typeof window != 'undefined' && ex?.response?.status == 401) {
                    window.location.reload();
                    return;
                }
                resolve({ status: false, message: ex.message, errorMessage: ex?.response?.data?.message, statusCode: ex?.response?.status });
            });
    });
};

export const postAuthWithAccessToken = (url, accessToken, entity, countryIsoCode = 'DE') => {

    const headers = {
        "content-type": "application/json",
        "x-access-token": accessToken,
        'country-iso-code': countryIsoCode
    };
    return new Promise((resolve, reject) => {
        axios
            .post(url, entity, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve(response.data);
                }
            })
            .catch((ex) => {
                if (typeof window != 'undefined' && ex?.response?.status == 401) {
                    window.location.reload();
                    return;
                }
                resolve({ status: false, message: ex.message, errorMessage: ex?.response?.data?.message, statusCode: ex?.response?.status });

            });
    });
};

export const putAuthWithAccessToken = (url, accessToken, entity, countryIsoCode = 'DE') => {

    const headers = {
        "content-type": "application/json",
        "x-access-token": accessToken,
        'country-iso-code': countryIsoCode
    };
    return new Promise((resolve, reject) => {
        axios
            .put(url, entity, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve(response.data);
                }
            })
            .catch((ex) => {
                if (typeof window != 'undefined' && ex?.response?.status == 401) {
                    window.location.reload();
                    return;
                }
                resolve({ status: false, message: ex.message, errorMessage: ex?.response?.data?.message, statusCode: ex?.response?.status });
            });
    });
};

export const deleteWithOutAuth = (url, countryIsoCode = 'DE') => {
    return new Promise((resolve, reject) => {
        const headers = {
            "content-type": "application/json",
            'country-iso-code': countryIsoCode
        };

        axios
            .delete(url, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve(response.data);
                }
            })
            .catch((ex) => {
                resolve({ status: false, message: ex?.message, errorMessage: ex?.response?.data?.message, statusCode: ex?.response?.status });
            });
    });
};