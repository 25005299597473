import Image from "next/image";
import { useRouter } from "next/router";
import MobileSidebar from "organisms/MobileSidebar";
import RegionalModal from "organisms/RegionalModal";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { loginWithEmail, verifyOtpWithEmail } from "services/auth.service";
import { updateCustomer } from "services/customer.service";
import {
  LOGIN_WITH_APPLE_URL,
  LOGIN_WITH_GOOGLE_URL,
} from "services/url.service";
import Icon from "../atoms/Icon";
import Logo from "../atoms/Logo";
import MenuList from "../atoms/MenuList";

import Button from "atoms/Button";
import Text from "atoms/Text";
import {
  countries,
  countryCurrency,
  localesOptions,
  isAvailableCountry
} from "constants/country-locale-list";
import Link from "next/link";
import LoginModal from "organisms/LoginModal";
import { removeAuth, setAuth } from "services/identity.service";
import { getUserCountry, getUserLocale, setUserCountry, setUserCurrencyInCookie, setUserLocale, getCountryFromCookies } from "services/locale.service";
import { getAuth } from "services/identity.service.js";

import SignUp from "./SignUp";
import { removeAllSavedStorageData } from "services/helper-function.service";
import { useUserStore } from "hooks/useUserStore";
import { toast } from "sonner";
import { useLocaleStore } from "hooks/useLocaleStore";
import LinkButton from "atoms/LinkButton";

function removeScrollOnBody() {
  document.body.style.overflow = 'hidden';
}
function addScrollOnBody() {
  document.body.style.overflow = 'unset';
};

function Header({
  logoSrc,
  menuItem,
  iconSrc,
  imgSrc,
  type = "homeScreen",
  className = "",
  isLoggedIn = false,
  isOpenLoginPopup = false,
  email = "",
  showLoginButton = true,
  classForContainer = 'md:px-[60px] pl-4 pr-1 w-full'
}) {
  const router = useRouter();
  const { user: currentUser, updateUser } = useUserStore();
  const { updateCountry } = useLocaleStore();

  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [isStickyPopup, setIsStickyPopup] = useState(false);
  const [isStickyRegionalPopup, setIsStickyRegionalPopup] = useState(false);
  const [showLoginWithOtpPopup, setShowLoginWithOtpPopup] = useState(isOpenLoginPopup);
  const [showSignupPopup, setShowSignupPopup] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(isLoggedIn);
  const [errorMessage, setErrorMessage] = useState(null);
  const [infoMessage, setInfoMessage] = useState(null);
  const [showRegionalModal, setShowRegionalModal] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const [selectedLocale, setSelectedLocale] = useState(localesOptions[0]);
  const [showMobileSidebar, setShowMobileSidebar] = useState(false);
  const [showLoginLogoutButton, setShowLoginLogoutButton] = useState(showLoginButton);
  const list = [
    {
      active: router.route === "/subscription-dashboard",
      activeImgSrc: "/images/icons/subscriptions-active.svg",
      href: "/subscription-dashboard",
      id: 1,
      imgSrc: "/images/icons/subscriptions.svg",
      text: "My subscriptions",
      onClick: () => { setShowMobileSidebar(false) }
    },
    // {
    //   active: router.route === "/account-dashboard",
    //   activeImgSrc: "/images/icons/profile-active.svg",
    //   href: "/account-dashboard",
    //   id: 2,
    //   imgSrc: "/images/icons/profile.svg",
    //   text: "My account",
    // },
    {
      active: showRegionalModal,
      activeImgSrc: "/images/icons/langauge-active.svg",
      id: 3,
      imgSrc: "/images/icons/langauge.svg",
      text: "Regional settings",
    },
  ];

  const listLoggedOutState = [
    {
      active: false,
      activeImgSrc: "/images/icons/logout-active.svg",
      id: 2,
      imgSrc: "/images/icons/logout.svg",
      text: "Login/SignUp",
      onClick: () => { setShowLoginPopup(true); setShowMobileSidebar(false) }
    },
    {
      active: showRegionalModal,
      activeImgSrc: "/images/icons/langauge-active.svg",
      id: 3,
      imgSrc: "/images/icons/langauge.svg",
      text: "Regional settings",
    },
    {
      active: false,
      activeImgSrc: "",
      id: 4,
      imgSrc: "",
      text: "Businesses",

    },
  ];

  const onOpenLoginPop = () => {
    setShowLoginPopup(true);
    router.push("#login", undefined, { shallow: true });
  };

  const onCloseLoginPop = () => {
    setShowLoginPopup(false);
    router.push(router.asPath.replace("#login", ""), undefined, {
      shallow: true,
    });
  };

  useEffect(() => {
    if (router.asPath.includes("#login") && !getAuth()) {
      setShowLoginPopup(true);
    }
  }, [router.asPath]);

  function onProfileClick() {
    if (!isUserLoggedIn) {
      onOpenLoginPop();
      setShowMobileSidebar(false);
    }
  }

  function loginWithGoogleHandler() {
    if (!router.asPath.includes('confirmation')) {
      localStorage.setItem(
        "redirect-url-after-login",
        router.asPath.replace("#login", "")
      );
    }
    router.push(LOGIN_WITH_GOOGLE_URL);
  }

  function loginWithAppleHandler() {
    if (!router.asPath.includes('confirmation')) {
      localStorage.setItem(
        "redirect-url-after-login",
        router.asPath.replace("#login", "")
      );
    }
    router.push(LOGIN_WITH_APPLE_URL);
  }

  async function loginWithEmailHandler(data) {
    const loginRes = await loginWithEmail({
      email: data.email,
      preferredLanguage: getUserLocale(),
    });

    if (loginRes.status) {
      setShowLoginWithOtpPopup(true);
      updateUser({ ...currentUser, email: data.email, session: loginRes.entity.session });
    } else {
      toast.error('Failed to send otp!');
    }
  }

  async function confirmOtpHandler(data) {
    const verifyRes = await verifyOtpWithEmail({
      code: data,
      email: currentUser.email,
      session: currentUser.session,
    });
    if (verifyRes.status && verifyRes.entity.code === "verified") {
      setAuth(verifyRes.entity);
      localStorage.setItem("user-logged-in-details", JSON.stringify(verifyRes.entity));
      onCloseLoginPop();
      updateUser({ ...currentUser, ...verifyRes.entity });

      if (typeof clevertap != 'undefined') {
        clevertap.event.push("UserLogin", {
          LoginMethod: "Email",
          Status: true,
        });
      }
      setIsUserLoggedIn(true);

      if (!verifyRes.entity.fullName || !verifyRes.entity.mobile) {
        setShowLoginWithOtpPopup(false);
        setShowSignupPopup(true);
      } else {
        const lsRedirectUrl = localStorage.getItem('redirect-url-after-login');
        if (isStickyPopup) {
          window.open((lsRedirectUrl || '/'), "_self");
          return;
        } else if (lsRedirectUrl) {
          window.open((lsRedirectUrl || '/'), "_self");
        } else {
          window.location.reload();
        }
      }
    } else {
      if (verifyRes.entity?.code === "wrongOtpException") {
        updateUser({ ...currentUser, session: verifyRes.entity.Session, });
        setErrorMessage("Invalid OTP !");
      } else {
        setErrorMessage("Session expired please try again !");
      }
    }
  }

  async function signupHandler(data) {
    const mobile = `${data.mobile.includes("+") ? data.mobile : "+" + data.mobile}`;
    const userUpdateRes = await updateCustomer(
      {
        fullName: data.fullName,
        mobile,
        preferredLanguage: getUserLocale(),
        country: getUserCountry()
      },
      currentUser.id
    );

    if (userUpdateRes.status) {
      localStorage.setItem(
        "user-logged-in-details",
        JSON.stringify(userUpdateRes.entity)
      );
      setAuth(userUpdateRes.entity);
      updateUser({ ...currentUser, ...userUpdateRes.entity });
      setIsUserLoggedIn(true);
      const lsRedirectUrl = localStorage.getItem('redirect-url-after-login');
      if (isStickyPopup) {
        window.open((lsRedirectUrl || '/'), "_self");
        return;
      } else if (lsRedirectUrl) {
        window.open((lsRedirectUrl || '/'), "_self");
      } else {
        window.location.reload();
      }
    }
  }

  async function resendHandler() {
    const loginRes = await loginWithEmail({ email: currentUser.email });
    if (loginRes.status) {
      setShowLoginWithOtpPopup(true);
      setInfoMessage("OTP sent to your email: " + currentUser.email);
      updateUser({ ...currentUser, session: loginRes.entity.session });

      setTimeout(() => {
        setInfoMessage(null);
      }, 5000);
    }
  }

  const logout = () => {
    localStorage.setItem('redirect-after-logout', router.asPath);
    removeAuth();
    setShowMobileSidebar(false);
    removeAllSavedStorageData();
    window.open(`${process.env.NEXT_PUBLIC_API_URL}/cognito-logout`, "_self");
  };

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 1000);
    }
  }, [errorMessage]);

  useEffect(() => {
    try {
      const data = JSON.parse(localStorage.getItem("user-logged-in-details"));
      const auth = getAuth();
      if (data && auth) {
        if (typeof clevertap != 'undefined') {
          clevertap.onUserLogin.push({
            Site: {
              Name: data.fullName,
              Identity: data.email,
              Email: data.email,
              Phone: data.mobile,
              PreferredLanguage: data.preferredLanguage,
            },
          });
        }

        const showSignupForm = localStorage.getItem("show-signup-form");
        if (showSignupForm && (!data.fullName || !data.mobile)) {
          setShowSignupPopup(true);
        } else {
          localStorage.removeItem('redirect-url-after-login');
        }
        updateUser({ ...currentUser, ...data })
        setIsUserLoggedIn(true);
      }
    } catch (err) {
      updateUser({});
      setIsUserLoggedIn(false);
      console.log("Error: ", err);
    }
  }, []);


  useEffect(() => {

    const country = router.query.country;
    const countryPreSelected = country || getCountryFromCookies();

    const currentCountry = isAvailableCountry(countryPreSelected)

    if (!currentCountry) {
      setIsStickyRegionalPopup(true);
      setShowRegionalModal(true);
      return;
    }

    setSelectedCountry(currentCountry);
    const userPreSelectedLocale = currentUser?.preferredLanguage || getUserLocale();

    const preSelectedLocal = localesOptions.find(
      (locale) => locale.value == userPreSelectedLocale
    );
    setUserLocale(preSelectedLocal.value);
    setUserCountry(countryPreSelected);
    updateCountry(countryPreSelected);
    setSelectedLocale(preSelectedLocal);
    setUserCurrencyInCookie(countryCurrency[currentCountry?.value]);

  }, []);

  useEffect(() => {
    if (showLoginPopup || showRegionalModal || showMobileSidebar) {
      removeScrollOnBody();
    } else {
      addScrollOnBody();
    }
  }, [showLoginPopup, showRegionalModal,showMobileSidebar]);

  useEffect(() => {

    const { query: { showLogin, redirect } } = router;

    const auth = getAuth();

    const redirectUrl = (redirect && redirect.slice(1, -1)) || null;

    if (showLogin == 'sticky') {
      if (!auth && redirectUrl) {
        setShowLoginPopup(true);
        setIsStickyPopup(true);
        localStorage.setItem(
          "redirect-url-after-login",
          `/${redirectUrl}`
        );
      } else if (auth && redirectUrl) {
        window.open(`/${redirectUrl}`, "_self");
      }
    }

  }, [router.asPath]);

  const handleCloseRegionalModal = () => {
    if (isStickyRegionalPopup)
      return;

    setShowRegionalModal(false)
  }

  return (
    <>
      {type === "default" && (
        <div className="flex justify-between items-center px-14 xl:px-16 2xl:px-20 py-5">
          <Logo src={logoSrc} width={153} height={44} id="logo" />
          <MenuList
            menuItem={menuItem}
            className="text-[#23262F] font-semibold"
          />
          <div className="flex 2xl:gap-10 xl:gap-8 gap-6">
            <div className="flex rounded-full bg-[#EFEFEF] p-3">
              <Icon iconSrc={iconSrc} iconAlt="Search Icon" id="search-icon" />
            </div>
            <div className="relative w-fit">
              <Icon
                iconSrc="/images/icons/profile-circle.svg"
                iconWidth={80}
                iconHeight={40}
                id="profile-icon"
                onClick={
                  isUserLoggedIn
                    ? () => setShowLogout(!showLogout)
                    : onProfileClick
                }
              />
              {showLogout && (
                <div className="absolute z-[62] right-0 top-full mt-1 w-[100px] xl:w-[130px] border border-secondary-200 py-1 xl:py-2 rounded bg-white cursor-pointer transition-all ease-out duration-300">
                  <Text
                    id='logout'
                    variant="bodySmall"
                    textColor="text-black-800"
                    fontFamily=""
                    className="pl-3 py-1.5 xl:py-2 hover:bg-neutral-600"
                    onClick={logout}
                  >
                    Logout
                  </Text>
                </div>
              )}
            </div>

            <Image src={imgSrc} width={47} height={15} id="hamburger-icon" />
          </div>
        </div>
      )}

      {type === "onlyLogo" && (
        <div
          className={`h-[73px] bg-neutral-900 px-[60px] py-[26px] ${className}`}
        >
          <Logo
            src="/images/zenova-white.svg"
            alt="logo"
            width={116}
            height={20}
            id="logo"
            onClick={() => { }}
          />
        </div>
      )}

      {type === "homeScreen" && (
        <>
          <RegionalModal
            showModal={showRegionalModal}
            closeModel={handleCloseRegionalModal}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            selectedLocale={selectedLocale}
            setSelectedLocale={setSelectedLocale}
            userData={currentUser}
          />
          <div className={`bg-neutral-900 sticky top-0 z-[61] h-[70px] md:h-[73px] ${className}`}>
            <div className={`flex justify-between items-center h-full md:px-[60px] pl-4 pr-1 w-full`}>
              <Logo
                id="home-screen-logo"
                alt="Logo"
                className="flex items-center"
                height={20}
                src="/images/LOGO.svg"
                width={116}
                onClick={() => window.open("/", "_self")}
              />

              <span
                className={`block md:hidden cursor-pointer relative ${showMobileSidebar
                  ? "w-[24px] h-[24px] mr-6"
                  : isUserLoggedIn
                    ? "w-8 h-8 mr-6"
                    : "w-[64px] h-[64px]"
                  }`}
                onClick={() => {
                  showMobileSidebar
                    ? (document.body.style.overflow = "unset")
                    : (document.body.style.overflow = "hidden"),
                    setShowMobileSidebar(!showMobileSidebar);
                }}
              >
                <Image
                  src={
                    showMobileSidebar
                      ? "/images/icons/close.svg"
                      : isUserLoggedIn
                        ? "/images/icons/profile-active.svg"
                        : "/images/icons/hamburger.svg"
                  }
                  layout="fill"
                  alt="hamburger"
                  id="profile-icon"
                />
              </span>
              <div className="hidden md:flex items-center gap-x-[11px]">
                <LinkButton
                  className="text-white no-underline mr-2"
                  href={`/en/${router.query.country}/businesses`}
                  // href={`en/de/businesses`}
                  variant="bodySmall"
                >
                  Businesses
                </LinkButton>
                
                <Button
                  id="navbar-langauge"
                  style="dark"
                  rounded="rounded-full"
                  fontSize="text-[12px]"
                  className="h-[38px] w-[38px] uppercase  flex items-center justify-center"
                  classForBtnText='pt-0.5'
                  btnText={selectedLocale?.label.substring(0, 2)}
                  onClick={() => {
                    setShowRegionalModal(!showRegionalModal);
                  }}
                />
                <div className="w-fit relative z-[100] group">

                  <Button
                    id='profile-button'
                    style="dark"
                    className="py-3 px-[14px] flex flex-row-reverse justify-center items-center gap-x-3 leading-[100%]"
                    rounded="rounded-[10px]"
                    fontSize="text-xs"
                    imgSrc="/images/icons/profile-blue.svg"
                    imgAlt="arrow"
                    imgWidth={20}
                    imgHeight={20}
                    classForBtnText=''
                    showIcon={isUserLoggedIn}
                    btnText={isUserLoggedIn ? (currentUser?.fullName || 'Profile') : "Login/Signup"}
                    onClick={onProfileClick}
                  />
                  {(
                    <div className={`${isUserLoggedIn ? 'hidden group-hover:block' : 'hidden'} absolute z-[62] right-0 top-full w-[150px] xl:w-[200px] border border-neutral-900 py-1 xl:py-2 rounded bg-neutral-900 cursor-pointer transition-all ease-out duration-300`}>

                      <Link href="/subscription-dashboard">
                        <Text
                          id='my-subscription'
                          variant="bodySmall"
                          textColor="text-white"
                          fontFamily=""
                          className="pl-3 py-1.5 xl:py-2 hover:bg-neutral-600"
                        >
                          My Subscription
                        </Text>
                      </Link>


                      {/* <Link href="/account-dashboard">
                      <Text
                      id='manage-account'
                      variant="bodySmall"
                      textColor="text-white"
                      fontFamily=""
                      className="pl-3 py-1.5 xl:py-2 hover:bg-neutral-600"
                      > 
                    Manage Account
                     </Text>
                    </Link> */}


                      <Text
                        id='logout'
                        variant="bodySmall"
                        textColor="text-white"
                        fontFamily=""
                        className="pl-3 py-1.5 xl:py-2 hover:bg-neutral-600"
                        onClick={logout}
                      >
                        Logout
                      </Text>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <MobileSidebar
        show={showMobileSidebar}
        className="h-[calc(100dvh-70px)] fixed inset-x-0 z-[100] bottom-0 block md:hidden"
        list={isUserLoggedIn ? list : listLoggedOutState}
        handleFunc={isUserLoggedIn ? logout : onProfileClick}
        isUserLoggedIn={isUserLoggedIn}
        handleClickOutSide={() => setShowMobileSidebar(false)}
        onClick={(id) => {
          if (id === 3) {
            setShowMobileSidebar(false);
            setShowRegionalModal(!showRegionalModal);
          }
        }}
      />

      <LoginModal
        showLoginModal={showLoginPopup}
        showOtp={showLoginWithOtpPopup}
        setShowLoginWithOtpPopup={setShowLoginWithOtpPopup}
        email={(currentUser.email || email)}
        isStickyPopup={isStickyPopup}
        handleCloseModal={() => {
          if (isStickyPopup) return;
          onCloseLoginPop();
          setShowLoginWithOtpPopup(false);
        }}
        handleGoogleLogin={loginWithGoogleHandler}
        handleAppleLogin={loginWithAppleHandler}
        handleSendOtp={loginWithEmailHandler}
        handleResendOtp={resendHandler}
        handleConfirmOtp={confirmOtpHandler}
        infoMessage={infoMessage}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />

      <SignUp
        key={currentUser}
        defaultValues={{
          fullName: currentUser.fullName,
          mobile: currentUser.mobile,
        }}
        signUp={signupHandler}
        showModal={showSignupPopup}
        closeModal={() => {
          setShowSignupPopup(false);
          localStorage.removeItem('show-signup-form');
        }}
      />
    </>
  );
}

Header.propTypes = {
  logoSrc: PropTypes.string,
  menuItemL: PropTypes.array,
  iconSrc: PropTypes.string,
  imgSrc: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
};

export default Header;
