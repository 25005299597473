import React, { useState } from "react";
import Heading from "atoms/Heading";
import Image from "next/image";
import Link from "next/link";
const MobileSidebar = ({
  className,
  list,
  handleFunc = () => { },
  onClick = () => { },
  show = false,
  isUserLoggedIn,
  handleClickOutSide=()=>{}
}) => {

  const [activeButton, setActiveButton] = useState(false);
  const [hoveredItemId, setHoveredItemId] = useState(null);

  return (
    <div
      className={`bg-black bg-opacity-75 flex justify-end transition-all duration-300 ${show ? "translate-x-0" : "translate-x-full"
        } ${className}`}
        onClick={handleClickOutSide}
    >
      <div className="h-full w-[70%] bg-neutral-900 flex flex-col justify-between py-12 px-[14px]" onClick={(e)=>e.stopPropagation()}>
        <ul className="space-y-7">
          {list.map((item) => (
            <Link key={item.id} href={item.href ? item.href : {}}>
              <li
                className="flex items-center gap-x-[14px] cursor-pointer transform duration-500 ease-in-out"
                onClick={() =>{ onClick(item.id), item.onClick && item.onClick();}}
                onMouseEnter={() => {
                  setHoveredItemId(item.id);
                  
                }}
                onMouseLeave={() => {
                  setHoveredItemId(null);
                 
                }}
              >
                { (item.imgSrc || item.imgSrc) && (
                  <span className="flex items-center w-[28px] h-[28px] md:w-[32px] md:h-[32px] relative">
                    <Image
                      src={item.active || item.id === hoveredItemId ? item.activeImgSrc : item.imgSrc}
                      alt="icon"
                      layout="fill"
                    />
                  </span>
                )}
                <Heading
                  type="h5"
                  fontSize="text-lg xs:text-2xl"
                  className={`font-semibold ${item.active || item.id === hoveredItemId ? "text-primary-500" : "text-primary-200"
                    }`}
                >
                  {item.text}
                </Heading>
              </li>
            </Link>
          ))}
        </ul>

      {isUserLoggedIn &&  <ul>
          <li
            className="flex items-center gap-x-[14px] cursor-pointer"
            onClick={handleFunc}
            onMouseEnter={() => setActiveButton(true)}
            onMouseLeave={() => setActiveButton(false)}
          >
            <span className="flex items-center w-[28px] h-[28px] md:w-[32px] md:h-[32px] relative">
              <Image
                src={
                  activeButton
                    ? "/images/icons/logout-active.svg"
                    : "/images/icons/logout.svg"
                }
                alt="logout"
                layout="fill"
              />
            </span>

            <Heading
              type="h5"
              fontSize="text-lg xs:text-2xl"
              className={`${activeButton ? "text-primary-500" : "text-primary-200 "
                } font-semibold`}
            >
              {isUserLoggedIn ? "Logout" : "Login/SignUp"}
            </Heading>
          </li>
        </ul>}
      </div>
    </div>
  );
};

export default MobileSidebar;
